import { useApi } from '@shared/api'
import { useAuthStore } from '@shared/stores/auth'
import { useToast } from '@shared/toaster'
import { allowedDomains, getLegacySlipmatKey } from '@shared/utils'
import { getNext } from '@slipmatio/toolbelt/vue'
import { defineStore } from 'pinia'

const versionString = import.meta.env.DEV ? `${import.meta.env.VITE_APP_VERSION}-dev` : import.meta.env.VITE_APP_VERSION
const GOOGLE_CLIENT_ID: string = import.meta.env.VITE_PUBLIC_GOOGLE_CLIENT_ID

export const useStore = defineStore('main', {
  state: () => ({
    debug: import.meta.env.DEV,
    version: versionString,
    env: {
      googleClientId: GOOGLE_CLIENT_ID,
      csrfToken: '',
      slipmatKey: '',
      loginToken: '',
    },
    authStore: markRaw(useAuthStore()),
    api: markRaw(useApi()),
    ui: {
      state: {
        helloDone: false,
        isInitialized: false,
        isLoading: false,
        isAuthenticated: false,
        isSavingData: false,
        apiUnreachable: false,
        dataExportStarted: false,
        fatalError: undefined as FatalErrorType | undefined,
      },
      navigation: {
        account: [
          { name: 'Account', path: '/', icon: 'i-ic-baseline-home' },
          {
            name: 'Edit Profile',
            path: '/edit-profile/',
            icon: 'i-ic-baseline-account-box',
          },
          { name: 'Settings', path: '/settings/', icon: 'i-ic-baseline-settings', needsFlag: 'account_settings' },
        ] as NavigationItem[],
        accountLinks: [
          { name: 'Documentation', path: 'https://docs.slipmat.io' },
          { name: 'Backstage', path: 'https://backstage.slipmat.io' },
          { name: 'Discord', path: 'https://discord.gg/EvB7DkrsnQ' },
        ] as NavigationItem[],
      },
    },
    user: null as null | User,
    legacyProfile: null as LegacyProfileData | null,
    dataExport: null as null | DataExport,
    userSocket: undefined as WebSocket | undefined,
    timezones: [] as Timezone[],
    toaster: useToast(),
    route: {
      to: '',
      from: '',
      next: '',
    },
  }),

  getters: {
    isReady: state => {
      return state.ui.state.isInitialized
    },
    hasFlag: state => (flag: FeatureFlag) => {
      return state.user?.meta.flags.includes(flag) || false
    },
  },

  actions: {
    async initApp() {
      if (!browserIsSupported()) {
        if (!storageAvailable('localStorage')) {
          this.ui.state.fatalError = FatalError.storage
        } else {
          this.ui.state.fatalError = FatalError.browser
        }
        return
      }

      const key = await getLegacySlipmatKey()
      this.env.slipmatKey = key
      localStorage.setItem('slipmatKey', key)

      try {
        const me = await this.authStore.ensureIsAuthenticated(this.router)
        if (me) {
          this.user = me
          this.ui.state.isAuthenticated = true

          const sockets = useSocketStore()
          this.userSocket = sockets.init()

          await this.fetchLegacyProfile()
        } else {
          this.ui.state.isAuthenticated = false
        }

        if (this.user) {
          logger.run(`App initialized for ${this.user.username}`)
        } else {
          logger.log('App initialized for unauthorized user ⚠️')
        }
        this.ui.state.isInitialized = true
      } catch (error) {
        this.handleApiError(error, true)
      }
    },

    handleApiError(error: unknown, silent = false) {
      this.ui.state.fatalError = FatalError.api
      logger.debug('Non-axios API Error: ', error)
      if (!silent) {
        throw error
      }
    },

    async updateUserProfile(data: UserPatch) {
      try {
        await this.api.patch('/account/update/', data)
        logger.log('user profile updated')
      } catch (error) {
        this.handleApiError(error)
      }
    },

    async deleteUserAccount() {
      if (window && window.confirm('Are you absolutely sure? This CANNOT BE UNDONE.')) {
        try {
          await this.api.post('/account/delete-account/')
          logger.log('deletion started')
          window.location.href = '/goodbye/'
        } catch (error) {
          this.handleApiError(error)
        }
      }
    },

    async fetchTimezones() {
      try {
        this.timezones = await this.api.get<Timezone[]>('/_internal/timezones/')
      } catch (error) {
        this.handleApiError(error)
      }
    },

    async fetchLegacyProfile() {
      try {
        this.legacyProfile = await this.api.get<LegacyProfileData>('/legacy/profile/')
      } catch (error) {
        this.handleApiError(error)
      }
    },

    async startuserDataExportBuild() {
      try {
        await this.api.post('/account/data-export/build/')
        this.ui.state.dataExportStarted = true
      } catch (error) {
        this.ui.state.dataExportStarted = false
        this.handleApiError(error)
      }
    },

    async getDataExportStatus() {
      try {
        this.dataExport = await this.api.get<DataExport>('/account/data-export/status/')
      } catch (error) {
        this.handleApiError(error)
      }
    },

    isPublicUrl(): boolean {
      const currentRoute = this.router.currentRoute.value
      return Boolean(
        currentRoute.meta &&
          typeof currentRoute.meta === 'object' &&
          'isPublic' in currentRoute.meta &&
          currentRoute.meta.isPublic === true
      )
    },

    handleGoogleCredentialResponse(response: google.CredentialResponse) {
      this.api
        .post<{ meta: { access_token: string; login_token: string | null } }>(
          '/auth/headless/app/v1/auth/provider/token',
          {
            provider: 'google',
            process: 'login',
            token: {
              client_id: this.env.googleClientId,
              id_token: response.credential,
            },
            slipmat_key: localStorage.getItem('slipmatKey'),
          }
        )
        .then(async result => {
          const next = getNext(allowedDomains, this.router)
          let nextWithToken = next
          const loginToken = result.meta.login_token
          if (loginToken && next.startsWith('http')) {
            this.env.loginToken = loginToken
            nextWithToken = `${next}?lt=${loginToken}`
          }
          localStorage.setItem('authToken', result.meta.access_token)
          localStorage.setItem('knownUser', 'true')

          this.ui.state.isAuthenticated = true
          if (nextWithToken.startsWith('http')) {
            window.location.href = nextWithToken
          } else {
            this.toaster.success('Logged in')
            await this.router.push(next)
          }
        })
        .catch(error => {
          logger.error('handleGoogleCredentialResponse error: ', error)
        })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
