import { useApi } from '@shared/api'
import { defineStore } from 'pinia'
import type { Router } from 'vue-router'

const loginUrl = import.meta.env.DEV ? 'http://localhost:5174/login/' : 'https://account.slipmat.io/login/'

function isSameDomain(targetUrl: string): boolean {
  // Create URL objects to compare domains
  const currentUrl = new URL(window.location.href)
  const destination = new URL(targetUrl)

  // Compare hostname (domain) and port if present
  return currentUrl.host === destination.host
}

/**
 * Removes a query parameter from a URL path
 * @param path - URL path with optional query parameters
 * @param param - Parameter name to remove
 * @returns Path without the specified parameter
 * @throws Error if path or param are invalid
 */
export function stripParam(path: string, param: string): string {
  if (!path || !param) {
    throw new Error('Path and param cannot be empty')
  }

  const [base, query] = path.split('?')

  if (!query) {
    return path
  }

  const params = query.split('&').filter(p => p.split('=')[0] !== param)

  return params.length ? `${base}?${params.join('&')}` : base
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as null | User,
    authState: {
      authIsChecked: false,
      isAuthenticated: false,
      apiUnreachable: false,
      fatalError: undefined as FatalErrorType | undefined,
    },
    api: markRaw(useApi()),
  }),
  actions: {
    handleApiError(error: unknown) {
      logger.log('handleApiError: ', error)
      this.authState.apiUnreachable = true
    },

    handleLoginRedirect(router: Router, targetUrl: string) {
      logger.run('handleLoginRedirect')
      if (window.location.pathname.startsWith('/login/')) {
        return
      }
      if (isSameDomain(targetUrl)) {
        router.push('/login/')
      } else {
        window.location.href = targetUrl
      }
    },

    /**
     * Returns User object if user is authenticated or redirects to login and returns null if not.
     * If the API returns other than 200 or 401, throws an error.
     */
    async ensureIsAuthenticated(router: Router, next?: string) {
      try {
        const response = await this.api.get<User>('/account/hello/')
        this.authState.isAuthenticated = true
        this.authState.apiUnreachable = false
        this.api.updateCsrfToken()
        logger.debug('ensureIsAuthenticated: user is authenticated')
        return response
      } catch (_error) {
        // TODO: check 401 or not
        logger.debug('ensureIsAuthenticated error: ', _error)
        const ltParam = router.currentRoute.value.query.lt as string | undefined
        if (ltParam) {
          const accessToken = await this.api.exchangeToken(ltParam)
          localStorage.setItem('authToken', accessToken)
          const cleanedUrl = stripParam(router.currentRoute.value.fullPath, 'lt')
          window.location.href = cleanedUrl
        } else {
          const nextUrl = next ? `?next=${next}` : ''
          this.handleLoginRedirect(router, `${loginUrl}${nextUrl}`)
        }
        // this.handleApiError(error)
      } finally {
        this.authState.authIsChecked = true
      }
      return null
    },

    async redirectIfAuthenticated(next?: string) {
      if (this.authState.authIsChecked) {
        if (this.authState.isAuthenticated && this.user) {
          window.location.href = next || '/'
        } else {
          return
        }
      } else {
        try {
          await this.api.get<User>('/account/hello/')
          window.location.href = next || '/'
        } catch (error) {
          logger.log('redirectIfAuthenticated error: ', error)
        }
      }
    },

    async logoutUser() {
      logger.run('logoutUser')
      try {
        await this.api.post('/account/logout/')
        localStorage.removeItem('authToken')
        this.authState.isAuthenticated = false
        if (window) {
          window.location.href = '/'
        }
      } catch (error) {
        this.handleApiError(error)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
