import { onMounted, onUnmounted, ref } from 'vue'

/**
 * Composable to manage Google Client script loading and initialization
 *
 * @example
 * ```ts
 * const { waitForGoogle, error } = useGoogleClient()
 *
 * onMounted(async () => {
 *   const google = await waitForGoogle()
 *   // Initialize Google client...
 * })
 * ```
 */
export function useGoogleClient() {
  const error = ref<Error | null>(null)
  let script: HTMLScriptElement | null = null
  let loadPromise: Promise<void> | null = null

  function loadScript(): Promise<void> {
    if (loadPromise) return loadPromise

    loadPromise = new Promise((resolve, reject) => {
      const existingScript = document.querySelector('script[src="https://accounts.google.com/gsi/client"]')

      if (existingScript) {
        resolve()
        return
      }

      script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true

      script.onload = () => resolve()
      script.onerror = () => {
        const err = new Error('Failed to load Google client script')
        error.value = err
        reject(err)
      }

      document.head.appendChild(script)
    })

    return loadPromise
  }

  async function waitForGoogle(): Promise<typeof google> {
    await loadScript()

    if (typeof google !== 'undefined') {
      return google
    }

    return new Promise((resolve, reject) => {
      let attempts = 0
      const checker = setInterval(() => {
        attempts++

        if (typeof google !== 'undefined') {
          clearInterval(checker)
          resolve(google)
          return
        }

        if (attempts >= 10) {
          clearInterval(checker)
          const err = new Error('Google client not available after script load')
          error.value = err
          reject(err)
        }
      }, 100)
    })
  }

  onMounted(() => {
    loadScript().catch(console.error)
  })

  onUnmounted(() => {
    if (script?.parentNode) {
      script.parentNode.removeChild(script)
    }
  })

  return {
    error,
    waitForGoogle,
  }
}
