import * as Sentry from '@sentry/vue'
import { loggerErrorHandler, loggerWarnHandler } from '@shared/logger'
import { createHead } from '@unhead/vue/client'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './VueApp.vue'
import router from './routes'

import '@/assets/css/account.css'
import '@fontsource/play/400.css'
import '@fontsource/play/700.css'

const head = createHead()
const pinia = createPinia()
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const app = createApp(App)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://c77a2843bbedf364fcfb22691cfb66b0@o15755.ingest.us.sentry.io/4508648225767424',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/account\.slipmat\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.config.performance = true
app.config.errorHandler = loggerErrorHandler
app.config.warnHandler = loggerWarnHandler

app.use(pinia)
app.use(router)
app.use(head)

app.mount('#app')
