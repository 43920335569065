import { decode, encode } from '@msgpack/msgpack'
import * as Sentry from '@sentry/browser'
import type { WsMessage } from '@shared/types'

const socketHost = import.meta.env.DEV ? 'ws://localhost:8000' : 'wss://api.slipmat.io'

function onSocketOpen(_event: Event) {
  // logger.debug('socket onopen: ', _event)
}
function onSocketClose(_event: Event) {
  // logger.log('socket onclose: ', _event)
}

export function decodeData(data: ArrayBuffer): object {
  // logger.debug('decodeData: ', data)
  try {
    return obfuscator.deobfuscate(data) as object
  } catch (error) {
    logger.warn('Data decode error.', error)
    Sentry.captureException(error)
    new Error('Data decode error.')
    return {}
  }
}

export function connectUserSocket(handler: (message: WsMessage) => void): WebSocket {
  const token = localStorage.getItem('authToken')
  if (token === null || token === 'undefined') {
    throw new Error('connectUserSocket: no token found')
  }
  const userSocket = new WebSocket(`${socketHost}/ws/user-messages/?token=${encodeURIComponent(token)}`)
  userSocket.binaryType = 'arraybuffer'
  userSocket.onopen = onSocketOpen
  userSocket.onclose = onSocketClose

  userSocket.onmessage = event => {
    handler(decodeData(event.data) as WsMessage)
  }
  return userSocket
}

class MessageObfuscator {
  private readonly key: Uint8Array

  constructor(key: string) {
    this.key = new TextEncoder().encode(key)
  }

  obfuscate(data: unknown): ArrayBuffer {
    const packed = encode(data)
    return this.xorEncode(new Uint8Array(packed))
  }

  deobfuscate(data: ArrayBuffer): ArrayBuffer {
    const packed = this.xorEncode(new Uint8Array(data))
    return decode(packed) as ArrayBuffer
  }

  private xorEncode(data: Uint8Array): ArrayBuffer {
    const result = new Uint8Array(data.length)
    for (let i = 0; i < data.length; i++) {
      // eslint-disable-next-line
      result[i] = data[i] ^ this.key[i % this.key.length]
    }
    return result.buffer
  }
}

export const obfuscator = new MessageObfuscator('HpOlemCKgjAetQGZ')
