import { captureException } from '@sentry/browser'
import { useApiStore } from '@shared/stores/api'

interface ApiResponse<T> {
  data: T
  status: number
  ok: boolean
}

export interface ApiError {
  message: string
  status: number
  response: Response
}

export type ApiGetEndpoint =
  | '/account/hello/'
  | '/legacy/profile/'
  | '/_internal/timezones/'
  | '/_internal/headers/'
  | '/account/data-export/status/'
  | '/internal/get-csrf-token/'
  | '/auth/exchange-token/'

export type ApiPostEndpoint =
  | '/account/logout/'
  | '/_internal/email-available/'
  | '/_internal/username-available/'
  | '/account/delete-account/'
  | '/account/data-export/build/'
  | '/auth/headless/app/v1/auth/provider/token'

function logQueryCount(response: Response) {
  try {
    const queryCount = response.headers.get('x-djangoquerycount-count')
    if (queryCount) {
      logger.log('SQL Queries: ', queryCount)
    }
  } catch {
    logger.debug('Failed to log query count')
  }
}

export function useApi() {
  async function fetchWithConfig<T>(endpoint: string, config: RequestInit): Promise<ApiResponse<T>> {
    const apiStore = useApiStore()
    apiStore.init()

    const url = `${apiStore.baseUrl}${endpoint}`
    const headers = {
      'Content-Type': 'application/json',
      ...(apiStore.authToken && { Authorization: `Bearer ${apiStore.authToken}` }),
      ...(apiStore.csrfToken && { 'X-CSRFTOKEN': apiStore.csrfToken }),
      ...config.headers,
    }

    const response = await fetch(url, { ...config, headers })

    if (!response.ok) {
      apiStore.healthy = false
      captureException(response)
      throw {
        message: response.statusText,
        status: response.status,
        response: response,
      } satisfies ApiError
    }
    apiStore.healthy = true

    if (import.meta.env.DEV) {
      logQueryCount(response)
    }
    const data = await response.json()

    return {
      data,
      status: response.status,
      ok: response.ok,
    }
  }

  return {
    updateCsrfToken() {
      const apiStore = useApiStore()
      apiStore.setCsrfToken()
    },

    updateauthToken(token?: string) {
      const apiStore = useApiStore()
      apiStore.setAuthToken(token)
    },

    async updateCsrfTokenFromServer() {
      const apiStore = useApiStore()
      logger.run('updateCsrfTokenFromServer')
      try {
        const tokenData = await this.get<TokenResponse>('/internal/get-csrf-token/')
        apiStore.setCsrfToken(tokenData.token)
      } catch (error) {
        logger.error('Failed to update CSRF token from server: ', error)
      }
    },

    async get<T>(endpoint: ApiGetEndpoint) {
      return (await fetchWithConfig<T>(endpoint, { method: 'GET', credentials: 'include' })).data
    },

    async exchangeToken(token: string) {
      const apiStore = useApiStore()
      logger.run('exchangeToken')
      const response = await fetchWithConfig<{ access_token: string }>(`/auth/exchange-token/?lt=${token}`, {
        method: 'GET',
        credentials: 'include',
      })
      logger.log('exchangeToken response: ', response)
      const at = response.data['access_token']
      apiStore.setAuthToken(at)
      return at
    },

    async post<T>(endpoint: ApiPostEndpoint, body?: unknown) {
      const config: RequestInit = {
        method: 'POST',
        credentials: 'include',
      }

      if (body) {
        config.body = JSON.stringify(body)
      }

      return (await fetchWithConfig<T>(endpoint, config)).data
    },

    async patch<T>(endpoint: string, body: unknown) {
      try {
        const response = await fetchWithConfig<T>(endpoint, {
          method: 'PATCH',
          credentials: 'include',
          body: JSON.stringify(body),
        })
        return response.data
      } catch (error) {
        return error as ApiError
      }
    },

    async delete<T>(endpoint: string) {
      try {
        const response = await fetchWithConfig<T>(endpoint, { method: 'DELETE', credentials: 'include' })
        return response.data
      } catch (error) {
        return error as ApiError
      }
    },
  }
}

export type UseApiType = ReturnType<typeof useApi>

export const api = useApi()
