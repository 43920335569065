import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import LoginView from '@/views/auth/LoginView.vue'

const LogoutView = () => import('@/views/auth/LogoutView.vue')
const AccountIndexView = () => import('@/views/account/AccountIndexView.vue')
const AccountIndexPage = () => import('@/components/pages/AccountIndexPage.vue')
const EditProfilePage = () => import('@/components/pages/EditProfilePage.vue')
const AccountSettingsPage = () => import('@/components/pages/AccountSettingsPage.vue')
const GoodbyeView = () => import('@/views/account/GoodbyeView.vue')

const history = import.meta.env.SSR ? createMemoryHistory() : createWebHistory()

const routes = [
  {
    path: '/',
    name: 'account',
    component: AccountIndexView,
    children: [
      {
        path: '',
        name: 'account-index',
        component: AccountIndexPage,
        meta: {
          title: 'Account',
        },
      },
      {
        path: 'edit-profile/',
        name: 'edit-profile',
        component: EditProfilePage,
        meta: {
          title: 'Edit Profile',
        },
      },
      {
        path: 'settings/',
        name: 'account-settings',
        component: AccountSettingsPage,
        meta: {
          title: 'Settings',
        },
      },
    ],
  },
  {
    path: '/login/',
    name: 'login',
    component: LoginView,
    meta: {
      isPublic: true,
      title: 'Login',
    },
  },
  {
    path: '/logout/',
    name: 'logout',
    component: LogoutView,
    meta: {
      title: 'Log Out',
    },
  },
  {
    path: '/goodbye/',
    name: 'goodbye',
    component: GoodbyeView,
    meta: {
      isPublic: true,
      title: 'Goodbye',
    },
  },
]

const router = createRouter({
  history: history,
  strict: true,
  routes,
})

// router.beforeEach((to, from, next) => {
//   logger.run('router.beforeEach')
//   const store = useStore()

//   if (!to.meta.isPublic && !store.ui.state.isAuthenticated) {
//     if (to.path === '/') {
//       next({ path: '/login/' })
//     } else {
//       next({ path: '/login/', query: { next: to.path } })
//     }
//   } else if (to.meta.isPublic && store.ui.state.isAuthenticated) {
//     next({ path: '/' })
//   } else {
//     next()
//   }
// })

router.afterEach((to, from) => {
  const store = useStore()

  store.route.to = to.fullPath
  store.route.from = from.fullPath
})

export default router
