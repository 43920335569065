import * as Sentry from '@sentry/browser'
import { LogLevel, useVueLogger } from '@slipmatio/logger'
import type { ComponentPublicInstance } from 'vue'

export const logger = useVueLogger('slipmat', LogLevel.DEBUG)

export function loggerErrorHandler(error: unknown, component: ComponentPublicInstance | null, info: string): void {
  logger.error(error, component, info)
  Sentry.captureException(error)
}

export function loggerWarnHandler(error: unknown, component: ComponentPublicInstance | null, info: string): void {
  logger.warn(error, component, info)
}
