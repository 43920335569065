<script setup lang="ts">
import AuthFrame from '@/components/frame/auth/AuthFrame.vue'
import { useGoogleClient } from '@/composition/thirdparty'

logger.log('SocialLoginView setup')

const { waitForGoogle, error: googleError } = useGoogleClient()

const store = useStore()
const gb = ref<HTMLElement | null>(null)
const googleClientId = computed(() => store.env.googleClientId)
const showLogin = ref(false)

await store.authStore.redirectIfAuthenticated('/')

onMounted(async () => {
  logger.log('SocialLoginView mounted')
  try {
    const key = localStorage.getItem('slipmatKey')
    const nick = localStorage.getItem('knownUser')
    if (key || nick) {
      if (key) {
        store.env.slipmatKey = key
      }
      showLogin.value = true

      const google = await waitForGoogle()

      google.accounts.id.initialize({
        client_id: googleClientId.value,

        callback: store.handleGoogleCredentialResponse,
      })

      if (gb.value) {
        google.accounts.id.renderButton(gb.value, {
          type: 'standard',
          theme: 'filled_blue',
          size: 'large',
        })
      } else {
        logger.error('gb is null')
      }
    } else {
      logger.error('No key or nick found in session storage')
    }
  } catch (err) {
    logger.error('Failed to initialize Google client:', err)
  }
})
</script>

<template>
  <AuthFrame>
    <template #title> Log In to Slipmat </template>

    <template #subtitle>
      <span class="text-orange-500">v3 Alpha</span> &ndash; for current Slipmat users and desktop only!
    </template>

    <template #main>
      <div v-show="showLogin" class="items-center justify-center py-8 mx-auto bg-black sm:rounded-lg max-w-60">
        <div ref="gb" class="flex justify-center mx-auto w-60" />
      </div>
      <div v-if="!showLogin" class="items-center justify-center py-8 mx-auto bg-black sm:rounded-lg max-w-60">
        Error! Slipmat login details not found.
        <a href="https://docs.slipmat.io/guides/v3-migration/">Read the documentation</a>, use a desktop browser and
        start again from the homepage.
      </div>

      <p v-if="googleError" class="mt-2 text-sm text-red-500">
        {{ googleError.message }}
      </p>
    </template>
  </AuthFrame>
</template>
