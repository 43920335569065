<script setup lang="ts">
const store = useStore()

const fatalError = computed(() => store.ui.state.fatalError)
</script>
<template>
  <div
    v-if="fatalError !== undefined"
    class="fixed inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50 z-[9999] flex-col"
  >
    <template v-if="fatalError === FatalError.browser">
      <h1 class="mb-4 text-2xl font-semibold">Error: browser not supported</h1>
      <p>
        Your browser is not supported; it's too old. See
        <a href="https://docs.slipmat.io/guides/technical/#supported-browsers" class="underline text-slipmat-500">
          our docs for details</a
        >. Please try a newer browser to continue.
      </p>
    </template>
    <template v-if="fatalError === FatalError.storage">
      <h1 class="mb-4 text-2xl font-semibold">Error: localStorage not available</h1>
      <p>
        Your browser has localStorage disabled or full. This site needs localStorage to function. See
        <a
          href="https://docs.slipmat.io/guides/technical/#how-to-allow-localstorage-website-data-in-common-browsers"
          class="underline text-slipmat-500"
        >
          our docs on how to allow storage to continue</a
        >.
      </p>
    </template>
    <template v-if="fatalError === FatalError.api">
      <h1 class="mb-4 text-2xl font-semibold">Error: API is unreachable</h1>
      <p>Slipmat API cannot be reached.</p>
    </template>
  </div>
</template>
