import type { Toast } from '@shared/types'
import { defineStore } from 'pinia'

export const useToastStore = defineStore('toasts', {
  state: () => ({
    toasts: [] as Toast[],
  }),
  actions: {
    addToast(toast: Toast) {
      this.toasts.push(toast)
    },
    removeToast(toastId: number) {
      const index = this.toasts.findIndex((toast: Toast) => toast.id === toastId)
      if (index > -1) {
        this.toasts.splice(index, 1)
      }
    },
  },
})
