<script setup lang="ts">
import FatalError from '@/components/frame/FatalError.vue'
import SlipmatToast from '@shared/components/slipmatioui/SlipmatToast.vue'

const store = useStore()
const router = useRouter()
const isInitialized = computed(() => store.ui.state.isInitialized)

onBeforeMount(async () => {
  await router.isReady()
  await store.initApp()
})
</script>
<template>
  <FatalError />
  <SlipmatToast />
  <Suspense>
    <router-view v-if="isInitialized" />
  </Suspense>
</template>
