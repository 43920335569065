// Enums

export enum FatalError {
  api = 0,
  browser = 1,
  storage = 2,
  unknown = 3,
}

export enum BackendEvent {
  DATA_EXPORT_STARTED = 'DATA_EXPORT_STARTED',
  DATA_EXPORT_DONE = 'DATA_EXPORT_DONE',
}

// Types

export type FeatureFlag = 'profile_edit' | 'account_settings'
export type SiteSetting = 'CHAT_OPEN' | 'SIGN_UP_OPEN'

// Interfaces

export interface Toast {
  id: number
  type: 'success' | 'error'
  text: string
  icon?: string
}

export interface NavigationItem {
  name: string
  path: string
  icon?: string
  needsFlag?: FeatureFlag
}

export interface DataExport {
  isReady: boolean
  size: number
  expiresAt: string
  created: string
}

export interface UserSettings {
  core: {
    experimentalFeatures: boolean
  }
  chat: {
    expandImages: boolean
  }
  live: {
    defaultTheme: string
  }
}

export interface User {
  uid: string
  username: string
  email: string
  name: string
  country: string
  meta: {
    version: number
    karma: number
    settings: UserSettings
    flags: FeatureFlag[]
  }
  hasLegacyKey: boolean
  isStaff?: boolean
}

export type UserPatch = Pick<User, 'uid'> &
  Partial<Omit<User, 'uid'>> & {
    legacyKey?: boolean
  }

export interface Toaster {
  success(message: string): void
}

export interface WsMessage {
  to: 'site' | 'user'
  content: string
  sender: string
  event?: BackendEvent
  extra?: unknown
}

export interface TokenResponse {
  token: string
  expiry?: string
}

export interface LegacyListEvent {
  id: string
  host: {
    name: string
    slug: string
    photo: string
  }
  photo: string
  title: string
  slug: string
  scheduledTime: string
}

export interface LegacyEventDetail {
  id: string
  title: string
  slug: string
  host: {
    name: string
    slug: string
    photo: string
  }
  photo: string
  tagString: string
  scheduledTime: string
  status: 'upcoming' | 'live' | 'archived'
}

export interface LegacyProfileData {
  username: string
  backstageUsername: string
  dateJoined: string
}

export interface Timezone {
  name: string
  value: string
}
