import { useToastStore } from '@shared/stores/toasts'

export const useToast = (): { success: (text: string) => void } => {
  const store = useToastStore()

  return {
    success: (text: string): void => {
      const toastId = new Date().getTime() / 10
      store.addToast({
        id: toastId,
        type: 'success',
        text: text,
      })
      setTimeout(() => {
        store.removeToast(toastId)
      }, 5000)
      logger.success('letstoast: ', text)
    },
  }
}
