import { getCookie } from '@slipmatio/toolbelt'
import { defineStore } from 'pinia'

export const useApiStore = defineStore('api', {
  state: () => ({
    authToken: null as null | string,
    csrfToken: null as null | string,
    baseUrl: import.meta.env.DEV ? 'http://localhost:8000' : 'https://api.slipmat.io',
    healthy: true,
    errorState: {
      numRetries: 0,
      fatalError: undefined as string | undefined,
    },
  }),
  actions: {
    setAuthToken(token?: string) {
      if (token) {
        this.authToken = token
      } else {
        this.authToken = localStorage.getItem('authToken')
      }
    },
    setCsrfToken(token?: string) {
      if (token) {
        this.csrfToken = token
      } else {
        this.csrfToken = getCookie('csrftoken')
      }
    },

    init() {
      this.setAuthToken()
      this.setCsrfToken()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApiStore, import.meta.hot))
}
