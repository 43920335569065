import { connectUserSocket, obfuscator } from '@shared/websockets'
import { defineStore } from 'pinia'

export const useSocketStore = defineStore('sockets', {
  actions: {
    init() {
      logger.run('Sockets inited')
      const userSocket = connectUserSocket(this.handleUserWsMessage)

      setTimeout(() => {
        const msg = {
          to: 'site',
          content: 'Hello everyone!',
        }
        userSocket.send(obfuscator.obfuscate(msg))
      }, 1000)

      return userSocket
    },

    handleDataExportDone(message: WsMessage) {
      // const main = useStore()
      logger.run('Data export done', message)
      // main.doToast('Tilin data valmiina ladattavaksi!', 'success')
      // if (main.me !== null) {
      //   main.me.dataExport = JSON.parse(message.extra as string) as UserDataExport
      // }
    },

    handleUserWsMessage(message: WsMessage) {
      // const main = useStore()
      // logger.debug('handleUserWsMessage: ', message)

      if (message.event === BackendEvent.DATA_EXPORT_STARTED) {
        logger.log('data export started')
      } else if (message.event === BackendEvent.DATA_EXPORT_DONE) {
        this.handleDataExportDone(message)
      } else {
        logger.debug('[unhandled usersocket.message]: ', message)
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSocketStore, import.meta.hot))
}
