import type { LegacyListEvent } from '@shared/types'
import dayjs from 'dayjs'

export const isDev = Boolean(import.meta.env.DEV)
// export const baseApiUrl = isDev ? 'http://localhost:8000' : 'https://api.slipmat.io'
export const baseApiUrl = 'http://localhost:8000'

export const allowedDevDomains = [
  'localhost:4321',
  'localhost:5174',
  'localhost:5175',
  'localhost:5176',
  'localhost:8000',
]

export const allowedProdDomains = ['slipmat.io']

export const allowedDomains = isDev ? allowedDevDomains : allowedProdDomains

export function isSSR() {
  try {
    return typeof window === 'undefined' && typeof document === 'undefined'
  } catch {
    return true
  }
}

export function errorHasMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as { message: unknown }).message === 'string'
  )
}

/**
 * Basic URL validation that checks if string can be parsed as URL
 * Input must start with http:// or https://
 */
export function isValidUrl(url: string): boolean {
  if (!url?.trim()) return false

  try {
    const parsed = new URL(url)
    return parsed.protocol === 'http:' || parsed.protocol === 'https:'
  } catch {
    return false
  }
}

/**
 * Simple helper to prefetch images
 * @param urls URL or array of URLs to prefetch
 */
export async function prefetchImages(urls: string | string[]): Promise<Array<{ url: string; success: boolean }>> {
  if (isSSR()) {
    return []
  }

  const urlList = Array.isArray(urls) ? urls : [urls]

  return Promise.all(
    urlList.map(
      url =>
        new Promise<{ url: string; success: boolean }>(resolve => {
          const img = new Image()

          function cleanup() {
            img.onload = null
            img.onerror = null
          }

          img.onload = () => {
            cleanup()
            resolve({ url, success: true })
          }

          img.onerror = () => {
            cleanup()
            resolve({ url, success: false })
          }

          img.src = url
        })
    )
  )
}

export function isBot() {
  try {
    const hasNormalBrowserFeatures = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || !navigator.webdriver

    return !hasNormalBrowserFeatures
  } catch {
    return true
  }
}

export function fetchLegacyEvents(eventType: 'live' | 'upcoming'): Promise<LegacyListEvent[]> {
  return fetch(`${baseApiUrl}/legacy/events/${eventType}/`)
    .then(response => (response.ok ? response.json() : []))
    .catch(error => {
      logger.error(`Error fetching ${eventType} legacy event data:`, error)
      return []
    })
}

export function formatDateStr(dateStr: string, format: 'long' | 'short' = 'long'): string {
  try {
    if (format === 'long') {
      return dayjs(dateStr).format('MMMM D, YYYY h:mm A')
    }
    return dayjs(dateStr).format('MMMM D')
  } catch (error) {
    logger.error('Invalid date string:', dateStr, error)
    return 'Invalid date'
  }
}

export async function videoAutoplayAllowed(testSource: string): Promise<boolean> {
  const video = document.createElement('video')

  video.volume = 0.01
  video.src = testSource
  video.muted = false
  video.style.display = 'none'
  document.body.appendChild(video)

  try {
    await video.play()
    return true
  } catch {
    return false
  } finally {
    video.pause()
    video.remove()
  }
}

export function boolFromLS(name: string) {
  try {
    return !!localStorage.getItem(name)
  } catch {
    return false
  }
}

/**
 * Reads a number from localStorage with a configurable fallback value
 * @param name The key to read from localStorage
 * @param defaultValue The number to return if the key isn't found or value isn't valid
 * @returns number from localStorage or defaultValue
 */
export function numFromLS(name: string, defaultValue = 0): number {
  try {
    const value = localStorage.getItem(name)
    // If no value in storage, return the default
    if (!value) return defaultValue

    const num = Number(value)
    // Return parsed number if valid, otherwise return default
    return Number.isFinite(num) ? num : defaultValue
  } catch {
    // Return defaultValue if localStorage is unavailable
    return defaultValue
  }
}

export async function getLegacySlipmatKey() {
  const keyUrl = import.meta.env.DEV ? 'http://localhost:8000/test/legacy-key/' : 'https://slipmat.io/key/'

  try {
    const response = await fetch(keyUrl, {
      credentials: 'include',
    })
    const html = await response.text()
    const match = html.match(/<p class="code">(.*?)<\/p>/)
    return match ? match[1] : ''
  } catch (error) {
    logger.warn('Error fetching Slipmat key: ', error)
  }
  return ''
}

export function usePreloader() {
  function normalizeUrl(path: string): string {
    try {
      return new URL(path, window.location.origin).href
    } catch {
      return path
    }
  }

  async function preloadSingle(path: string) {
    try {
      if (!path) return

      const url = normalizeUrl(path)
      const link = document.createElement('link')
      link.rel = 'preload'
      link.href = url
      link.as = url.endsWith('.js')
        ? 'script'
        : url.endsWith('.css')
          ? 'style'
          : url.match(/\.(jpg|png|gif|webp)$/)
            ? 'image'
            : 'fetch'

      document.head.appendChild(link)
      await Promise.race([
        new Promise(resolve => {
          link.onload = link.onerror = () => {
            link.remove()
            resolve(undefined)
          }
        }),
        new Promise(resolve => setTimeout(resolve, 5000)),
      ])
    } catch {
      logger.debug('Failed to preload:', path)
    }
  }

  async function preloadUrl(paths: string | string[]) {
    try {
      const urlArray = Array.isArray(paths) ? paths : [paths]
      await Promise.all(urlArray.map(path => preloadSingle(path)))
    } catch {
      logger.debug('Failed to preload:', paths)
    }
  }

  return { preloadUrl }
}
