<script setup lang="ts">
const store = useStore()
const apiUnreachable = computed(() => store.ui.state.apiUnreachable)
</script>
<template>
  <div v-if="apiUnreachable" class="p-3 border text-white border-slipmat-500">
    <h3 class="pb-2 font-semibold">Error: Slipmat API is unreachable</h3>

    <p class="text-xs">The site cannot operate without an API connection. Please try again later.</p>

    <p class="text-xs">
      Please check your network connection.
      <a href="https://stats.uptimerobot.com/bsMhkXtBq0">Slipmat status page</a> and Social media profiles might have
      some updates if the site is down.
    </p>
  </div>
</template>
